import * as React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Title: React.FC<TextProps> = props => {
  return (
    <Text
      as="h2"
      fontSize="xl"
      fontWeight={600}
      pb={2}
      mb={4}
      borderBottom="2px solid black"
      {...props}
    />
  );
};

export default Title;
