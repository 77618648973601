import axios from 'axios';
import {
  LoginInput,
  Plans,
  TokenData,
  TolettaResponse,
  UserData,
} from '../types';
import { ConnectAllowModels } from '../assets/connect_allow_models';

const API = (token?: string) =>
  axios.create({
    baseURL: process.env.GATSBY_TOLETTA_API_BASE_URL + 'api/v2/',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'json',
  });

const isBrowser = () => typeof window !== 'undefined';

export function setUserToken(token: string): void {
  window.localStorage.setItem('gatsbyUserToken', JSON.stringify(token));
}

export function setUserPlan(plan: string): void {
  window.localStorage.setItem('gatsbyUserPlan', JSON.stringify(plan));
}

export function getUserToken(): string {
  if (isBrowser()) {
    const token = window.localStorage.getItem('gatsbyUserToken');
    if (!token) return '';
    return JSON.parse(token);
  }
  return '';
}

export function getUserPlan(): Plans {
  if (isBrowser()) {
    const plan = window.localStorage.getItem('gatsbyUserPlan') || '';
    if (!plan || plan === 'undefined') return '';
    return JSON.parse(plan);
  }
  return '';
}

export function isLoggedIn(): boolean {
  return !!getUserToken();
}

export function isStandardPlan() {
  let plan = getUserPlan();
  return plan && plan.includes('スタンダード');
}

export function isTolettaPlan() {
  let plan = getUserPlan();
  return plan && plan.includes('台数');
}

export function isFreePlan() {
  let plan = getUserPlan();
  return plan && plan.includes('フリー');
}

export async function login({
  username,
  password,
}: LoginInput): Promise<TolettaResponse<TokenData>> {
  return await API().post('apl/auth/login', { username, password });
}

export async function resetPassword(
  email: string
): Promise<TolettaResponse<string>> {
  return await API().post('apl/user/forget', { email });
}

export function logout(): void {
  setUserToken('');
}

export async function getUser(
  token: string
): Promise<TolettaResponse<UserData>> {
  return await API(token).get('apl/user/me');
}

export async function changePassword(
  token: string,
  id: number,
  password: string
): Promise<TolettaResponse<''>> {
  const postData = {
    id,
    password,
  };
  return await API(token).put('apl/user/pchange', postData);
}

export async function updateUser(
  token: string,
  userData: UserData
): Promise<TolettaResponse<''>> {
  return await API(token).post('apl/user', userData);
}

export async function isSubscribeUser(token: string): Promise<boolean> {
  // 接続許可モデルがサブスクモデルかを判定する
  const userData = await getUser(token);
  return ConnectAllowModels.SUBSCRIBE === userData.data.connect_allow_model;
}
