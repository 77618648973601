import * as React from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import BackDrop from './Backdrop';
import Title from './Title/Title';

type LayoutProps = {
  children: React.ReactNode;
  location: Location;
  isMaxW?: boolean;
  maxW?: string;
};

const MainteBoard = () => (
  <div>
    <Title title="メンテナンスのお知らせ" />
    <Text>
      もうしわけありません、現在メンテナンスを行なっております。
      <br />
      復旧まで少々お待ちください・・・
      <br />
      【復旧予定時刻：{`${process.env.GATSBY_MAINTE_END_TIME}`}】
    </Text>
  </div>
);

const Layout = ({
  children,
  location,
  isMaxW = false,
  maxW = '600px',
}: LayoutProps) => {
  return (
    <>
      <BackDrop />
      <Header location={location} />
      <Box w={isMaxW ? `100%` : `90%`} maxW={maxW} margin={`0 auto`} pt={20}>
        {process.env.GATSBY_IS_MAINTE === 'true' ? (
          <MainteBoard />
        ) : (
          <main>{children}</main>
        )}
      </Box>
      <Footer mt={location?.pathname === '/' ? 0 : 20} />
    </>
  );
};

export default Layout;
