import * as React from 'react';
import { navigate } from 'gatsby';
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { FaUser, FaShoppingCart } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';
import { isLoggedIn } from '../repository/tolettaApi';

type HeaderProps = {
  location: Location;
};

const Header = ({ location }: HeaderProps) => {
  const [cart, _] = useRecoilState(cartState);

  function handleRootLink() {
    if (isLoggedIn()) navigate('/app/');
    else navigate('/login');
  }
  function handleCheckoutLink() {
    navigate('/checkout/');
  }
  function countCartItem() {
    let sum: number = 0;
    cart.forEach(i => {
      sum += i.quantity;
    });
    return sum;
  }

  const excludePath = [
    '/',
    '/notes',
    '/notes/',
    '/login',
    '/login/',
    '/reset-password',
    '/reset-password/',
    '/register',
    '/register/',
    '/init-checkout',
    '/init-checkout/',
    '/draft-ordered',
    '/draft-ordered/',
    '/init-thanks',
    '/init-thanks/',
  ];

  return (
    <Flex
      as="header"
      position="fixed"
      zIndex="docked"
      w="full"
      h={16}
      bg="white"
      justify="center"
      alignItems="center"
      borderBottom="1px solid #D5D5D5"
    >
      <Box as="a" w={`144px`} href="https://jp.tolettacat.com/">
        <StaticImage src="../images/logo.png" alt="logo" placeholder="none" />
      </Box>
      {!isLoggedIn() &&
      location?.pathname !== '/login' &&
      location?.pathname !== '/login/' &&
      location?.pathname !== '/register/' &&
      location?.pathname !== '/register' ? null : excludePath.includes(
          location?.pathname
        ) ? null : (
        <Flex justify="flex-end" alignItems="center" flexGrow={1}>
          <Tooltip label="ホーム" color="white" bg="brand.pink">
            <IconButton
              variant="unstyled"
              aria-label="home"
              icon={<FaUser />}
              onClick={handleRootLink}
            />
          </Tooltip>
          <Box position="relative">
            <Tooltip label="カート" color="white" bg="brand.pink">
              <IconButton
                variant="unstyled"
                aria-label="cart"
                icon={<FaShoppingCart />}
                onClick={handleCheckoutLink}
              />
            </Tooltip>
            <Box position="absolute" top={-1} right={3}>
              <Text as="span">{countCartItem()}</Text>
            </Box>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
