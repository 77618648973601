import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Flex, CircularProgress } from '@chakra-ui/react';
import { loadingState } from '../atoms/PageAtom';

const BackDrop = () => {
  const [isLoading, _] = useRecoilState(loadingState);
  if (!isLoading) return <Box />;
  return (
    <Box
      position="fixed"
      opacity={0.4}
      h="full"
      w="full"
      top={0}
      left={0}
      bg="black"
      zIndex="tooltip"
    >
      <Flex justify="center" alignItems="center" h="100vh">
        <CircularProgress color="brand.pink" isIndeterminate />
      </Flex>
    </Box>
  );
};

export default BackDrop;
