import * as React from 'react';
import { Box, Text, Flex, useBreakpointValue } from '@chakra-ui/react';

import CircleTextImg from '../images/txt_circle.svg';
import IconCatImg from '../images/icon_cat.svg';
import '../styles/footer.css';

const FooterLinks = () => (
  <>
    <Box w={{ base: `100%`, md: '33%' }} mb={2}>
      <Text fontSize="13px">
        <a
          href="http://jp.tolettacat.com/pages/terms"
          title="利用規約"
          rel="noopener noreferrer"
          target="_blank"
        >
          利用規約
        </a>
      </Text>
    </Box>
    <Box w={{ base: `100%`, md: '33%' }} mb={2}>
      <Text fontSize="13px">
        <a
          href="http://jp.tolettacat.com/pages/privacy"
          title="プライバシーポリシー"
          rel="noopener noreferrer"
          target="_blank"
        >
          プライバシーポリシー
        </a>
      </Text>
    </Box>
    <Box w={{ base: `100%`, md: '33%' }} mb={2}>
      <Text fontSize="13px">
        <a
          href="http://jp.tolettacat.com/pages/law"
          title="特定商取引法に基づく表記"
          rel="noopener noreferrer"
          target="_blank"
        >
          特定商取引法に基づく表記
        </a>
      </Text>
    </Box>
  </>
);

type FooterProps = {
  mt?: number;
};

const Footer = ({ mt = 20 }: FooterProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="footer"
      bg="brand.background2"
      textAlign="center"
      fontSize="xs"
      position="relative"
      mt={mt}
    >
      <Box
        position="absolute"
        top={`-18%`}
        left={0}
        right={0}
        margin={`auto`}
        w={`100px`}
        h={`100px`}
      >
        <Box
          w="full"
          h="full"
          position="absolute"
          top={0}
          left={0}
          className="rotate_circle"
        >
          <CircleTextImg />
        </Box>
        <Box
          w="40%"
          h="40%"
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          margin={`auto`}
        >
          <IconCatImg />
        </Box>
      </Box>
      <Box bg="brand.background" w="full" p={12} pt={20}>
        {isMobile ? (
          <Box
            textAlign="center"
            fontWeight={600}
            borderTop="1px solid black"
            pt={4}
          >
            <FooterLinks />
          </Box>
        ) : (
          <Flex
            justify="space-evenly"
            textAlign="center"
            fontWeight={600}
            borderTop="1px solid black"
            pt={4}
          >
            <FooterLinks />
          </Flex>
        )}
      </Box>
      <Box bg="white" p={4}>
        <Text
          color="#999"
          letterSpacing=".06em"
          fontWeight={`400`}
          fontFamily="Lato,sans-serif"
        >
          Copyright© Toletta Cats Inc. All Rights Reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
