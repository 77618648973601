import { atom } from "recoil";

export const loadingState = atom<boolean>({
  key: "loadingState",
  default: false,
});

export const reloadState = atom<boolean>({
  key: "reloadState",
  default: false,
});
